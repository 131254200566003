import React from 'react'
import { motion } from 'framer-motion'

const styleSvg = {
    opacity: 1,
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    flexShrink: 0,
    userSelect: "none",
}
const styleSvgPath = {
    strokeLinecap: "round",
}

const initFill = "var(--color-warning-text)"
const initStroke = "var(--color-warning-main)"
const hoverStroke = "var(--color-warning-text)"
const hoverFill = "var(--color-warning-main)"

const AnimatingSvgInMenuItem = ({ title, index, ctlAnimItem, dIconPath, refHoveringItemIndex }) => {

    const varSvg = {
        initial: false,
        animation: (index) => {
            return {
                rotateX: [ 90, 0 ],
                scale: 1,
                transition: {
                    duration: 0.1,
                }
            }
        },
        hover: (index) => {
            if (index === refHoveringItemIndex.current) {
                return {
                    transition: {
                        // delayChildren: 0.1,
                        // staggerChildren: 0.1
                    }
                }
            }
        },
        hoverBack: (index) => {
            if (index === refHoveringItemIndex.current) {
                return {
                    scale: 1,
                    transition: {
                        // delayChildren: 0.1,
                        // staggerChildren: 0.1
                    }
                }
            }
        }
    }

    const varSvgPath = {
        initial: false,
        animation: ({ index, initStroke, initFill, hoverStroke, hoverFill }) => {
            // console.log("index path animmation", index)
            return {
                transition: { duration: 0.1 },
                pathLength: 1,
            }
            // }
        },

        hover: ({ index, initStroke, initFill, hoverStroke, hoverFill }) => {
            // console.log("index path hover", index)
            if (index === refHoveringItemIndex.current) {
                return {
                    // scale: 1.3,
                    stroke: [ initStroke, hoverStroke ], // Changes the stroke color to red on hover
                    fill: [ initFill, hoverFill ], // Changes the fill color to green on hover
                    pathLength: 0, // Animates the path length to 1 on hover
                    // strokeDashoffset: [ 100, 0 ],
                    transition: {
                        duration: 1.5,

                    }
                }
            }
        },
        hoverBack: ({ index, initStroke, initFill, hoverStroke, hoverFill }) => {
            // console.log("index path animmation", index)
            if (index === refHoveringItemIndex.current) {

                return {
                    transition: { duration: 1 },
                    stroke: initStroke,
                    fill: initFill,
                    pathLength: 1,
                }
            }
        },
    }

    return (
        <motion.svg
            variants={varSvg}
            animate={ctlAnimItem}
            className="svgH"
            custom={index}
            style={{
                ...styleSvg,
                rotateX: 0
            }
            }
            focusable="false" viewBox="0 0 24 24"
            aria-hidden="true"
            key={`${title}-svgH`}
        >
            <motion.path
                variants={varSvgPath}
                animate={ctlAnimItem}
                custom={{ index, initFill, initStroke, hoverStroke, hoverFill }}
                className="svgPathH"
                style={{
                    ...styleSvgPath,

                    pathLength: 0,
                    pathSpacing: 0,
                }}
                d={dIconPath}
                strokeLinejoin="round"
                strokeDasharray="0 1"
                stroke={initStroke}
                fill={initFill}
                key={`${title}-svgPathH`}
            />
        </motion.svg>
    );
}

export default AnimatingSvgInMenuItem