import React, { createContext, useReducer, useLayoutEffect, useEffect, useContext, } from "react"

import {
    COLOR_MODE_KEY,
    DARK_MODE_COLORS,
    LIGHT_MODE_COLORS,
} from './constants';

const SET_THEME = "SET_THEME"
const SET_INITIALIZED = "SET_INITIALIZED"
const TOGGLE_MODE = "TOGGLE_MODE"

type TReducerState = {
    darkMode: boolean | undefined
    initialized: boolean
}
type TAction =
    { type: "SET_THEME", payload: boolean } | { type: "TOGGLE_MODE" } | { type: "SET_INITIALIZED" }





const initialDarkModeState: TReducerState = {
    darkMode: false,
    initialized: false,
}

type TProviderProps = {
    children: React.ReactNode
}

type TUseDarkModeContext = {
    state: TReducerState,
    toggleDarkMode: () => void
}

const initialDarkModeContext: TUseDarkModeContext = {
    state: initialDarkModeState,
    toggleDarkMode: () => { }
}


export const DarkModeContext = createContext<TUseDarkModeContext>(initialDarkModeContext)
export const useDarkMode = () => useContext(DarkModeContext)

export const darkModeReducer = (state: TReducerState, action: TAction): TReducerState => {
    switch (action.type) {
        case "SET_THEME":
            return {
                ...state,
                darkMode: action.payload
            }
        case "TOGGLE_MODE":
            return {
                ...state,
                darkMode: !state.darkMode
            }
        case "SET_INITIALIZED":
            return {
                ...state,
                initialized: true
            }

        // default: {
        //     throw new Error(`Unhandled action type: ${action.type}`)
        // }
    }
}
const useDarkModeContext = (initialDarkModeState: TReducerState) => {
    const [state, dispatch] = useReducer(darkModeReducer, initialDarkModeState)

    const setInitialized = () => dispatch({ type: SET_INITIALIZED })

    const toggleDarkMode = () => {
        dispatch({
            type: TOGGLE_MODE,
        })
    }


    if (typeof window !== 'undefined') {

        if (state.initialized) {
            const isDarkMode = state.darkMode ?? false
            // console.log("context isDarkMode", isDarkMode)
            localStorage.setItem(COLOR_MODE_KEY, isDarkMode.toString())
            const root = window.document.documentElement;
            // define for root CSS variables with colors available for selected color mode
            // console.log("DARK_MODE_COLORS", DARK_MODE_COLORS)
            // console.log("LIGHT_MODE_COLORS", LIGHT_MODE_COLORS)
            const currentColors = isDarkMode ? DARK_MODE_COLORS : LIGHT_MODE_COLORS
            // console.log("context currentColors adding to root.style", currentColors)
            Object.entries(currentColors).forEach(([key, objWrap]) => {
                Object.entries(objWrap).forEach(([key2, color]) => {
                    const cssVarName = "--color-".concat(key, "-", key2);
                    root.style.setProperty(cssVarName, color);
                })
            })

            isDarkMode && root.setAttribute("data-theme", "dark");
            (!isDarkMode) && root.removeAttribute("data-theme");

        }
    }

    useEffect(() => {
        const isDarkMode = document.documentElement.hasAttribute("data-theme")
        // console.log("useLayoutEffect context document.documentElement.hasAttribute(data-theme)", document.documentElement.hasAttribute("data-theme"))
        setInitialized()
        dispatch({
            type: SET_THEME,
            payload: isDarkMode
        })
    }, [])

    return { state, toggleDarkMode }
}


/** ProviderDarkMode
 * providing context info about currently used colored mode - light or dark
 * and function to toggle between them
 * followingly defined colors for the mode are applied
 * in Gatsby SSR and MUI5 important are also plugins 'gatsby-plugin-top-layout' & 'gatsby-plugin-mui-emotion'
 * @param  {} {children}
 */
export const ProviderDarkMode = ({ children }: TProviderProps) => {

    return (

        <DarkModeContext.Provider value={useDarkModeContext(initialDarkModeState)} >
            {children}
        </DarkModeContext.Provider>
    )
}