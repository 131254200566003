import type { GatsbyBrowser } from "gatsby"
import "@fontsource/roboto/latin-ext-400.css"
import "@fontsource/roboto/latin-ext-300.css"
import "@fontsource/roboto/latin-ext-700.css"
import "@fontsource/roboto/latin-ext-100.css"
import "@fontsource/roboto/latin-ext-400-italic.css"
import "@fontsource/roboto/latin-ext-300-italic.css"
import "@fontsource/roboto/latin-ext-700-italic.css"
import "@fontsource/roboto/latin-ext-100-italic.css"
import "./src/styles/MuiBaseLine.css"
import "./src/styles/clay.css"

import React from "react"
import Layout from './src/components/_shared/_layout';

import { MEDIA_QUERIES } from "./src/theme/constants"
import { BreakpointProvider } from "./src/components/_shared/contextBreakpointMediaQ";

import { ProviderDarkMode } from "./src/theme/ContextDarkMode"
import ProviderIsHomeFirstMount from "./src/components/_shared/contextIsHomeFirstMount"
import ProviderGdprCookieConsent from "./src/components/_shared/ContextGdprCookieConsent"
import ProviderCursorType from "./src/components/_shared/contextCursorType"
import { SnackbarProvider } from 'notistack';

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {

  return (
    <BreakpointProvider queries={MEDIA_QUERIES}>
      <ProviderDarkMode>
        <SnackbarProvider maxSnack={3}>
          <ProviderCursorType>
            <ProviderGdprCookieConsent>
              {element}
            </ProviderGdprCookieConsent>
          </ProviderCursorType>
        </SnackbarProvider>
      </ProviderDarkMode>
    </BreakpointProvider>
  )
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }, ...args) => {
  const { pageContext } = props;
  const { contentDir } = pageContext

  if (contentDir === "animacie") return (
    <ProviderIsHomeFirstMount>
      <Layout {...props}>
        {element}
      </Layout>
    </ProviderIsHomeFirstMount>
  )
  else return (
    <Layout {...props}>
      {element}
    </Layout>
  )
};

const transitionDelay = 500;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};