import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { Link as MuiLink } from "@mui/material";
import * as stylesClay from "../../../../styles/clay.module.css"

const variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const varSvg = {
    open: {
        rotateX: [ 0, 90, 0 ],
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.3,
        }
    },
    closed: {
        opacity: 0,
        y: 20,
    }
};



/**
 * SideMenuItem
 *  called when display < md
 * animated when motion parent animates open or closed
 * @param  {} {i
 * @param  {} title
 * @param  {} path
 * @param  {} dIconPath}
 */
export const SideMenuItem = ({ i, title, path, dIconPath }) => {
    return (
        <MuiLink
            component={Link}
            to={path}
            className="linkWrap"
            underline="none"
            key={`link-${title}`}
        >
            <motion.li
                variants={variants}
                whileHover={{ scale: 1.1 }}
                className={`${stylesClay.clay}`}
                key={`li-${title}`}
            >
                <motion.svg
                    className="svg"
                    variants={varSvg}
                    focusable="false"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    style={{
                        y: 20,
                        margin: '0 8px',
                    }}
                    aria-hidden="true"
                    key={`side-svg-${title}`}
                >
                    <path
                        d={dIconPath}
                        className="svgPath"
                        strokeLinejoin="round"
                        key={`side-svgPath-${title}`}
                    />
                </motion.svg>
                <div className="text-placeholder">
                    {title}
                </div>
            </motion.li>
        </MuiLink>
    );
};