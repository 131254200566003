import React, { lazy, Suspense } from 'react';

const LazyDynamicComponent = ({ relPath, compProps = {}, chunkName = `pagesContents/${relPath}`, isQuick = false, isLast = false }) => {
    // const chunkNameClean = chunkName.replace(/\//g, '_')  //`pagesContents_${relPath.replace(/\//g, '_')}`
    const LazyComp = isQuick ? lazy(() => import(
        /* webpackMode: "lazy", webpackPreload: true */
        `../pagesContents/${relPath}`
    )) : isLast ? lazy(() => import(
        /* webpackMode: "lazy", webpackPrefetch: true */
        `../pagesContents/${relPath}`
    )) : lazy(() => import(
        /* webpackMode: "lazy" */
        `../pagesContents/${relPath}`
    ))
    return (
        <Suspense
        // fallback={<div>Loading...</div>}
        >
            <LazyComp {...compProps}
            // key={chunkName}
            />
        </Suspense >
    );
}

export default LazyDynamicComponent;
// const compareProps = (prevProps, nextProps) => {
//     return JSON.stringify(prevProps) === JSON.stringify(nextProps);
//     // return prevProps.deviceSize === nextProps.deviceSize && prevProps.title === nextProps.title
// }

// export default React.memo(LazyDynamicComponent, compareProps)
/* webpackChunkName: '[chunkName]' */
/*webpackMode: "eager", preload: true */
/*webpackMode: "lazy", prefetch: true */
/*webpackMode: "eager", preload: true */
/* webpackMode: "lazy", preload: true, webpackChunkName: `${chunkNameClean}` */
// /*webpackMode: "lazy", webpackChunkName: `${chunkNameClean}` */