import React, { useEffect, useRef } from "react"; //useContext
import { motion, useCycle, useAnimation, } from "framer-motion" //useMotionValue
import { Link } from "gatsby";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/system/Box";
import useTheme from '@mui/material/styles/useTheme'
import { Link as MuiLink } from "@mui/material";
import SideDrawerFM from "./sideDrawerFM/SideDrawerFM";
import { HamburgerButton } from "./HamburgerButton";
import { navLinks } from "./navLinks";
import { useScroll, } from 'framer-motion' //useViewportScroll useInView
import { debounce } from "@mui/material";
import AnimatingSvgInMenuItem from "./AnimatingSvgInMenuItem";

const styleSxWrap = (leftShift) => ({
  pl: leftShift ? `${leftShift}px` : 0,
  zIndex: 2, // { xs: (theme) => theme.zIndex.drawer + 2, md: 1 },
  position: "fixed",
  top: 0,
  // left: "10px",
  // right: "10px",
  // mx: '10px',
  left: 0,
  right: 0,
  ml: "auto",
  mr: "auto",
  display: "block",
  boxSizing: "border-box",
  // zIndex: 1,
  "& .toolbar": {
    display: "grid",
    width: '100%'
  },
  "& .contT": {
    display: "inline-flex",
    justifyContent: 'space-around',
    flexDirection: 'row',
    // bgcolor: "primary.main",
    // color: "primary.contrastText",
    "& a": {
      display: "inline-flex",
    },
    "& .navMdUp": {
      display: { xs: "none", md: "contents" },
      opacity: 0.2,
    },
    "& .burger": {
      display: { xs: "flex", md: "none" }
    },
    "& .paper": {
      "&.MuiPaper-elevation3": {
        padding: "3px",
        display: "flex",
        maxWidth: "100%",
        // color: "black",
        borderRadius: "5px",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    "& .wrapLink": {
      borderRadius: "inherit",
      transition: "all 1s ease-out",

      "&.MuiLink-root": {
        position: "relative",
        display: "inline-block",
        "&::before": {
          transition: "all 1s ease-out",
          zIndex: 1,
          content: '""',
          position: "absolute",
          width: 0,
          height: "1px",
          bottom: "-1px",
          left: 0,
          bgcolor: "info.main",
          visibility: "hidden",

        },
        '&:hover , &:focus': {
          "&::before": {
            visibility: "visible",
            width: "100%",
          }
        },
        "&[aria-current='page']": {
          // borderRadius: "50%",
          boxShadow: '0px 4px 0px 0px var(--color-info-main)',
        },
      },

      "& .divListItem": {

        display: "inline-flex",
        borderRadius: "inherit",
        px: "5px",
        mx: "2px",
        bgcolor: "primary2.main",
        color: "primary2.contrastText",
        // boxShadow: 11,
        // boxShadow: "1px 7px 7px -4px var(--color-primary2-text), 0px 11px 15px 1px var(--color-primary2-text), 0px 4px 20px 3px var(--color-primary2-text)",
        boxShadow: '0px 0px 2px 0px var(--color-primary2-text)',
        py: 0, //[ 0.5, 0.5, 0.5, 1 ],
        "& .MuiTypography-root": {
          fontSize: [ 'medium', 'small', 'small', 'medium', 'large' ], //'0.9rem',
          width: 'max-content',
        },
        "& .divListItemIcon": {
          // bgcolor: "primary.main",
          opacity: 1,
          alignSelf: "center",
          display: "flex",
          pr: 0.25,
          "& .MuiIconButton-root": {
            color: "inherit",
          },
          "&.MuiListItemIcon-root": {
            color: "inherit",
            minWidth: "unset",
          },
        },
      },

    }
  }
})

const varHeader = {
  initial: false,
  animation: {
    transition: {
      staggerChildren: 0.2,
      duration: 0.3,
    },
  },
}

const varNav = ({ palette }) => {
  return {
    initial: false,
    animate: {
      opacity: 1,
      rotateX: 0,
      transition: {
        staggerChildren: 0.2,
        duration: 0.3,
        when: "beforeChildren",
      },

    },
    whileHover: { scale: 2.05 },
  }
}


const varDivListItemIcon = ({ palette, index }) => {
  return {
    initial: false,
    animation: {
      rotateY: 360,
      transition: {
        duration: 0.1,
        when: "beforeChildren",
      },

    },
  }
}


function Header({ contentDir, isDesktop = false, myStyle, heightHeader,
  leftShift = 0,
  ...restProps }) {
  const theme = useTheme()
  const [ isSideDrawerOpen, toggleIsOpen ] = useCycle(false, true);
  const ctlAnimItem = useAnimation()
  // const ctlAnimItems = navLinks.slice(1).map(() => useAnimation());
  const ctlAnimAllItems = useAnimation()
  const ctlAnimHeader = useAnimation()
  const refHoveringItemIndex = useRef(-1)
  const refViewed = useRef(null)
  const { scrollY } = useScroll(refViewed);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])


  const varDivListItem = {
    initial: false,
    animation: ({ index }) => {
      const delayValue = index * 0.1
      return {
        y: [ 0, -10, 0 ],
        scale: 1,
        transition: {
          delay: delayValue,
          duration: 0.2,
          when: "beforeChildren",
        },
      }
    },
    hover: ({ index }) => {
      if (index === refHoveringItemIndex.current) {
        return {
          scale: 1.05,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        }
      }
    },
    hoverBack: ({ index }) => {
      if (index === refHoveringItemIndex.current) {
        return {
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        }
      }
    }
  }


  useEffect(() => {

    const scrollYChangeHandler = (y) => {
      if (isMounted.current) {
        if (y > 80) {
          ctlAnimHeader.stop()
          ctlAnimHeader.start({
            opacity: [ null, 1, 1, 0 ],
            transition: { duration: 3, times: [ 0, 0.1, 0.9, 1 ] },
            // transitionEnd: { zIndex: 0 } 
          })
          ctlAnimAllItems.start("animation")
        } else {
          ctlAnimHeader.stop()
          ctlAnimHeader.start({ opacity: 1, zIndex: 1000, transition: { duration: 0.2 } })
        }
      }
    }
    const scrollYChangeHandlerDebounced = debounce(scrollYChangeHandler, 1300)
    // const unsubscribeScrollY = scrollY.onChange(scrollYChangeHandlerDebounced)
    const unsubscribeScrollY = scrollY.on("change", scrollYChangeHandlerDebounced)

    return () => {
      unsubscribeScrollY()
    }
  }, [ ctlAnimHeader, ctlAnimAllItems, scrollY ])


  useEffect(() => {
    ctlAnimAllItems.start("animation")
  })

  return <>
    <Box component={motion.header}

      variants={varHeader}
      initial="initial"
      animate={ctlAnimHeader}
      ref={refViewed}
      className="appBar MuiPaper-elevation4 clay"
      sx={{
        ...styleSxWrap(leftShift),

      }}
      id="top-menu"
    >
      <Toolbar
        sx={{ ...myStyle, height: heightHeader }}
        className="toolbar"
      >
        <Container maxWidth="md"
          className="contT"
          sx={{ alignItems: 'center' }}
        >
          <div
            className="MuiList-root MuiList-padding"
            key="wrapHome"
          >
            <MuiLink
              component={Link} to={navLinks[ 0 ].path} key="web7"
              className="wrapLink"
              underline="none"
              activeClassName="active"
            >
              <div
                className="paper MuiPaper-elevation3 MuiPaper-rounded"
              >
                <motion.div
                  // variants={varDivListItemHome({ palette: theme.palette, index: 0 })}
                  initial="initial"
                  variants={varDivListItem}
                  animate={ctlAnimItem}
                  custom={{ index: 0 }}
                  className="divListItem MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                  tabIndex={0}
                  aria-disabled="false"
                  key={`divListItemHome`}
                  onHoverStart={(mouseEvent, eventInfo) => {
                    // console.log("onHoverStart index 0, refHoveringItemIndex.current", 0, refHoveringItemIndex.current)
                    refHoveringItemIndex.current = 0;
                    ctlAnimItem.start("hover")
                  }}
                  onHoverEnd={() => {
                    // console.log("onHoverEnd index 0, refHoveringItemIndex.current", 0, refHoveringItemIndex.current)
                    ctlAnimItem.stop()
                    isMounted.current && ctlAnimItem.start("hoverBack");
                    isMounted.current && ctlAnimItem.start("animation");
                  }}
                >
                  <motion.div
                    className="divListItemIcon MuiListItemIcon-root"
                    variants={varDivListItemIcon({ palette: theme.palette, index: 0 })}
                    animate={ctlAnimItem}
                    style={{ rotateY: 0 }}
                    key="varDivListItemIconH"
                  >
                    <AnimatingSvgInMenuItem title={navLinks[ 0 ].title} index={0} ctlAnimItem={ctlAnimItem} dIconPath={navLinks[ 0 ].dIconPath} refHoveringItemIndex={refHoveringItemIndex} />

                  </motion.div>
                  <ListItemText primary="web 7"
                    primaryTypographyProps={{ variant: "h3" }}
                  />
                </motion.div>
              </div>
            </MuiLink>
          </div>

          <motion.nav
            variants={varNav({ palette: theme.palette })}
            initial="initial"
            animate={ctlAnimAllItems}
            className="navMdUp MuiList-root MuiList-padding"
            style={{ rotateX: 90, opacity: 0 }}
            aria-labelledby="main navigation"
            key="animNav"
          >
            {navLinks.map(({ title, path, dIconPath }, index) => {
              if (index === 0) return null;
              return (
                <MuiLink component={Link} to={path} key={`${title}-link`}
                  className="wrapLink"
                  underline="none"
                  activeClassName="active"
                >
                  <div
                    className="paper MuiPaper-elevation3 MuiPaper-rounded"
                    key={`${title}-paper-${index}`}
                  >
                    <motion.div
                      // variants={varDivListItem({ index: index })}
                      variants={varDivListItem}
                      animate={ctlAnimItem}
                      custom={{ index: index }}
                      className="divListItem MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                      // style={{ y: -50 }}
                      tabIndex={index}
                      aria-disabled="false"
                      key={`${title}-divListItem`}
                      // whileHover="hover" //{{ scale: 1.1 }} // Add this line
                      onHoverStart={(mouseEvent, eventInfo) => {
                        // console.log("onHoverStart index, refHoveringItemIndex.current", index, refHoveringItemIndex.current)
                        refHoveringItemIndex.current = index;
                        ctlAnimItem.start("hover")
                      }}
                      onHoverEnd={() => {
                        // console.log("onHoverEnd index, refHoveringItemIndex.current", index, refHoveringItemIndex.current)
                        ctlAnimItem.stop()
                        ctlAnimItem.start("hoverBack");
                        ctlAnimItem.start("animation");
                      }}
                    >
                      <motion.div
                        className="divListItemIcon MuiListItemIcon-root"
                        variants={varDivListItemIcon({ palette: theme.palette, index: index + 1 })}
                        animate={ctlAnimItem}
                        style={{ rotateY: 0 }}
                        key={`${title}-divListItemIcon`}
                        whileHover={{ scale: 1.15 }}
                      >
                        <AnimatingSvgInMenuItem title={title} index={index} ctlAnimItem={ctlAnimItem} dIconPath={dIconPath} refHoveringItemIndex={refHoveringItemIndex} />
                      </motion.div>
                      <ListItemText
                        primary={title}
                        primaryTypographyProps={{ variant: "h3" }}
                      />
                    </motion.div>
                  </div>
                </MuiLink>
              )
            })}
          </motion.nav>

          <HamburgerButton
            toggle={() => toggleIsOpen()}
            isOpen={isSideDrawerOpen}
            color={theme.palette.primary.contrastText} //{isSideDrawerOpen ? theme.palette.primary.contrastText : theme.palette.primary.contrastText}
            bgColor={isSideDrawerOpen ? theme.palette.primary.main : "transparent"}
          />
        </Container>
      </Toolbar>
    </Box>

    <SideDrawerFM
      isOpen={isSideDrawerOpen}
      heightHeader={heightHeader}
    />
  </>

}

export default Header;
