import React, { useState, useEffect, useContext, createContext } from 'react';

interface BreakpointProviderProps {
  queries: {
    [media: string]: string | undefined;
  };
  children: React.ReactNode;
}

const BreakpointProvider: React.FC<BreakpointProviderProps> = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState<{ [media: string]: boolean }>({});
  const [currBreakpoint, setCurrBreakpoint] = useState<string>('');

  useEffect(() => {
    const mediaQueryLists: { [media: string]: MediaQueryList } = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches: { [media: string]: boolean } = keys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media as keyof typeof mediaQueryLists] && mediaQueryLists[media as keyof typeof mediaQueryLists]!.matches);
        acc[media] && setCurrBreakpoint(media);
        return acc;
      }, {} as { [media: string]: boolean });
      setQueryMatch(updatedMatches);
    };

    if (typeof window !== 'undefined' && window.matchMedia) {
      const matches: { [media: string]: boolean } = {};
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]!);
          matches[media] = mediaQueryLists[media].matches;
          matches[media] && setCurrBreakpoint(media);
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={currBreakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};



const defaultValue = {}

const BreakpointContext = createContext(defaultValue);





function useBreakpoint() {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }

  return context;
}
export { useBreakpoint, BreakpointProvider };