import createPalette from '@mui/material/styles/createPalette.js';
import { DARK_MODE_COLORS, LIGHT_MODE_COLORS } from '../theme/constants';

const darkThemePalette = createPalette({ mode: "dark" });

Object.entries(DARK_MODE_COLORS).forEach(([ key, objName2Color ]) => {
  const mainColor = objName2Color.main
  const textColor = objName2Color.text2

  if (key === "background") {
  } else {
    darkThemePalette[ key ] = darkThemePalette.augmentColor({ name: key, color: { main: mainColor, contrastText: textColor } })
    darkThemePalette[ key ].grad = `linear-gradient(45deg, ${darkThemePalette[ key ].main} 30%, #FF8E53 90%)`
  }
});

const lightThemePalette = createPalette({ mode: "light" });

Object.entries(LIGHT_MODE_COLORS).forEach(([ key, objName2Color ]) => {
  const mainColor = objName2Color.main
  const textColor = objName2Color.text2
  if (key === "background") {
  } else {
    lightThemePalette[ key ] = lightThemePalette.augmentColor({ name: key, color: { main: mainColor, contrastText: textColor } })
  }
});

export { lightThemePalette, darkThemePalette } 