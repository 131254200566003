import * as React from "react";
import { motion } from "framer-motion";
import { SideMenuList } from "./SideMenuList";
import { Box } from "@mui/system";
// import svgMazanica from "../../../../../static/sf/im/masks/mazanica2.svg"
import pngMazanica from "../../../../../static/sf/im/masks/mazanica2.png"


const varSidebar = {
  open: {
    height: "auto",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  },
  closed: {
    height: 0,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const varWrap = {
  open: {
    x: "0%",
  },
  closed: {
    x: "100%",
  }
}

const sxWrap = {
  "&.wrapSideDrawerFM": {
    position: "fixed",
    display: { xs: "flex", md: "none" },
    justifyContent: "center",
    right: "0",
    bottom: "0",
    zIndex: '9',
    height: 'fit-content',
    ".background": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      width: "100%",
      bgcolor: "primary.main",
      // color: "primary.contrastText",
      maskImage: `url(${pngMazanica})`,
      maskSize: "cover",
    },
    "& ul.menuList,\nli": { margin: "0", padding: "0" },
    "& ul.menuList": {
      padding: "55px 25px",
      position: "relative",

      li: {
        color: "primary.contrastText",
        listStyle: "none",
        marginBottom: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        padding: "5px",
        cursor: "pointer",
        "& .icon-placeholder": {
          borderRadius: "50%",
          flex: "40px 0",
          marginRight: "20px",
        },
        "& .text-placeholder": {
          borderRadius: "5px",
          height: "20px",
        },
      },
    },
  },
}


/**
 * SideDrawerFM 
 * shown on < sm
 * for navigation
 * animated in and out
 * @param  {} {isOpen
 * @param  {} heightHeader}
 */
const SideDrawerFM = ({ isOpen, heightHeader }) => {
  return (
    <Box
      component={motion.nav}
      className="wrapSideDrawerFM"
      sx={sxWrap}
      variants={varWrap}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      style={{ x: "100%", top: heightHeader }}
    >
      <motion.div
        className="background"
        variants={varSidebar}
        style={{
          height: "auto"
        }}
        key="sideBg"
      />
      <SideMenuList />
    </Box>
  )
}

export default SideDrawerFM