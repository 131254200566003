import * as React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useDarkMode } from '../../src/theme/ContextDarkMode';
import { darkTheme, lightTheme } from '../../src/theme/theme';

type TProps = {
  children: React.ReactNode
}
export default function TopLayout({ children }: TProps) {
  // const { darkMode } = useDarkModeState()
  const { state } = useDarkMode()
  const { darkMode } = state
  // const showTheme = (typeof darkMode) !== "undefined" ? true : false
  // const theme = darkMode ? darkTheme : lightTheme
  const theme = state.darkMode ? darkTheme : lightTheme;
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      {/* {showTheme && <ThemeProvider theme={theme}> */}
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* This applies the global styles */}
        {children}
      </ThemeProvider>
    </React.Fragment>
  );
}

