import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import { lightThemePalette, darkThemePalette } from "./muiModePalettes";
import { BREAKPOINTS } from './constants';

const baseModeProps = {
  breakpoints: {
    values: {
      ...BREAKPOINTS
    },
  },
  typography: {
    fontSize: 8,
    htmlFontSize: 16,
    // fontFamily: ["Montserrat", "sans-serif"].join(","),
    // fontFamily: "Montserrat",
    // fontFamily: "'Work Sans', sans-serif",
    // body2: {
    //   fontSize: "1rem",
    // },
    // body: {
    //   // fontSize: [ "0.8rem", "1rem", "1.1rem", "1.2rem" ],
    //   fontSize: "1rem",
    // },
    // h1: {
    //   fontSize: "2.5rem",
    // },
    // h2: {
    //   fontSize: "2.3rem",
    // },
    // h3: {
    //   fontSize: "2rem",
    // },
    // h4: {
    //   fontSize: "1.8rem",
    // },
    // h5: {
    //   fontSize: "1.5rem",
    // },
    // h6: {
    //   fontSize: "1.2rem",
    // },
    // p: {
    //   fontSize: "2rem"
    // },
    // fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
  },
  // overrides: {
  //   MuiTypography: {
  //     root: {
  //       textDecoration: "line-through",
  //       backgroundColor: "grey"
  //     }
  //   }
  // },
  shape: {
    borderRadius: 20,
  },
  spacing: 8,

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
}

const lightThemeNoResponsive = createTheme({
  ...baseModeProps,
  colorMode: "light",
  palette: lightThemePalette,
  components: {
    // This is where you can globally override MUI components.
    MuiCssBaseline: {
      styleOverrides: `body {
        background-color: ${lightThemePalette.primary2.main};
        color: ${lightThemePalette.primary2.contrastText};
        }`,
    },
  }
})

const lightTheme = responsiveFontSizes(lightThemeNoResponsive, { breakpoints: [ 'xs', 'sm', 'md', 'lg', 'xl' ], factor: 4 });

// const lightTheme = createTheme({
//   ...baseModeProps,
//   colorMode: "light",
//   palette: lightThemePalette,
// })

const darkThemeNoResponsive = createTheme({
  ...baseModeProps,
  colorMode: "dark",
  palette: darkThemePalette,
  components: {
    // This is where you can globally override MUI components.
    MuiCssBaseline: {
      styleOverrides: `body {
        background-color: ${darkThemePalette.primary2.main};
        color: ${darkThemePalette.primary2.contrastText};
        }`,
    },
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //       h1 {
  //         color: green;
  //       }
  //     `,
  //   },
  // },
})
const darkTheme = responsiveFontSizes(darkThemeNoResponsive, { breakpoints: [ 'xs', 'sm', 'md', 'lg', 'xl' ], factor: 4 });


const lin = (angle, color1, spread1, color2, spread2) => `linear-gradient(${angle}deg, ${color1} ${spread1}, ${color2} ${spread2})`
const lin3 = (angle, color1, spread1, color2, spread2, color3, spread3) => `linear-gradient(${angle}deg, ${color1} ${spread1}, ${color2} ${spread2}, ${color3} ${spread3})`
const rad = (circleAt, color1, spread1, color2, spread2) => `radial-gradient(circle at ${circleAt}, ${color1} ${spread1}, ${color2} ${spread2})`
const rad3 = (circleAt, color1, spread1, color2, spread2, color3, spread3) => `radial-gradient(circle at ${circleAt}, ${color1} ${spread1}, ${color2} ${spread2}, ${color3} ${spread3})`
const radialGradientCol1_0Col2_25Transparent_75 = (color1, color2) => `radial-gradient(circle at 50% 50%, ${color1} 0%, ${color2} 25%, transparent 75%, transparent 100%)`
const gradF = { lin, rad, lin3, rad3, radialGradientCol1_0Col2_25Transparent_75 }
darkTheme.gradients = gradF
lightTheme.gradients = gradF

const distinctShadow1 = '4px 4px 0 rgb(0 0 0 / 80%), 8px 8px 0 rgb(0 0 0 / 60%), 12px 12px 0 rgb(0 0 0 / 40%), 16px 16px 0 rgb(0 0 0 / 20%), 20px 20px 0 rgb(0 0 0 / 5%)'
const distinctShadow2 = '4px 4px 0 rgb(0 0 0 / 60%), 8px 8px 0 rgb(0 0 0 / 40%), 12px 12px 0 rgb(0 0 0 / 20%), 16px 16px 0 rgb(0 0 0 / 10%)'
const distinctShadow3 = '4px 4px 0 rgb(0 0 0 / 40%), 8px 8px 0 rgb(0 0 0 / 20%), 12px 12px 0 rgb(0 0 0 / 10%)'

darkTheme.distinctShadow1 = distinctShadow1
lightTheme.distinctShadow1 = distinctShadow1
darkTheme.distinctShadow2 = distinctShadow2
lightTheme.distinctShadow2 = distinctShadow2
darkTheme.distinctShadow3 = distinctShadow3
lightTheme.distinctShadow3 = distinctShadow3

export { darkTheme, lightTheme }

export default lightTheme