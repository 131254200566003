interface ColorMode {
  [category: string]: {
    [colorName: string]: string;
  };
}

export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const MEDIA_QUERIES = {
  // (min-width: 30em) and (max-width: 50em) 
  // xs: '(max-width: 320px)',
  // sm: '(max-width: 720px)',
  xs: '(max-width: 640px)',
  sm: '(min-width: 641px) and (max-width: 768px)',
  md: '(min-width: 769px) and (max-width: 1024px)',
  lg: '(min-width: 1025px) and (max-width: 1280px)',
  xl: '(min-width: 1281px)',
  // or: '(orientation: portrait)', // we can check orientation too
}

export const COLORS: ColorMode = {
  text: {
    // light: 'hsl(0deg, 0%, 10%)',
    light: 'hsl(0deg, 0%, 2%)',
    dark: 'hsl(0deg, 0%, 98%)',
  },
  background: {
    light: 'hsl(0deg, 0%, 100%)',
    dark: 'hsl(250deg, 70%, 7%)',
  },
  primary: {
    light: 'hsl(340deg, 100%, 40%)',
    dark: 'hsl(50deg, 100%, 50%)',
  },
  secondary: {
    light: 'hsl(250deg, 100%, 50%)',
    dark: 'hsl(190deg, 100%, 40%)',
  },
  // Grays, scaling from least-noticeable to most-noticeable
  gray300: {
    light: 'hsl(0deg, 0%, 70%)',
    dark: 'hsl(0deg, 0%, 30%)',
  },
  gray500: {
    light: 'hsl(0deg, 0%, 50%)',
    dark: 'hsl(0deg, 0%, 50%)',
  },
  gray700: {
    light: 'hsl(0deg, 0%, 30%)',
    dark: 'hsl(0deg, 0%, 70%)',
  },
};


export const LIGHT_MODE_COLORS: ColorMode = {
  background: { default: "#DCE2E5", main: "#DCE2E5", text: "#012638", },
  primary: {
    main: "#b0bec5",
    text: "#012638",// "#000000", // "#113321",
  },
  secondary: {
    main: "#eef1f3", // "#bccfd9", // "#b28e59",
    text: "#02375a", // "#000000", //"#050709",
  },
  primary2: {
    main: "#527283",
    text: "#FEFEFE",
  },
  secondary2: {
    main: "#cfd7db", // "#fbe1ba",
    text: "#014b70",// "#000000", //"#6B4000"
  },
  error: {
    main: "#f8565b",
    text: " #1D0029",
  },
  warning: {
    main: "#ee9d6e",
    text: "#231A15",
  },
  info: {
    main: "#D8C5A3",
    text: "#294429",
  },
  success: {
    main: "#93ED99",
    text: "#2B462E",
  },
};




export const DARK_MODE_COLORS: ColorMode = {
  background: { default: "#250416", main: "#250416", text: "#fff2f9", },
  primary: {
    main: "#2a0519", // "#880e4f",
    text: "#fff2f9", // "rgb(0 0 0 / 20%)", //'hsl(0deg 0% 100% / 80%)',// "#FFFACD",
  },
  secondary: {
    main: "#37474f",
    text: "#f7fcff", // "rgb(0 0 0 / 20%)", // "#F2F1EF",
  },
  primary2: {
    main: "#460025",
    text: "#ffd3ea", // "rgb(0 0 0 / 20%)", // "#D3D3D3",
  },
  secondary2: {
    main: "#475c68",
    text: "#E0FFFF",
  },
  error: {
    main: "#b4024b",
    text: "#ffe8f1",// "#FEFEFE",
  },
  warning: {
    main: "#735816",
    text: "#fff6df",// "#E0FFFF",
  },
  info: {
    main: "#873C0E",
    text: "#fbf2ec",// "#F1F227",
  },
  success: {
    main: "#355E2E",
    text: "#eafbe7",// "#C5EFF7",
  },

};

export const THEME_COLOR_NAMES = Object.keys(LIGHT_MODE_COLORS).filter((colorName) => colorName !== 'background');
export const COLOR_MODE_KEY = 'color-mode-dark';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
export const INITIAL_COLOR_MODE_PROP = 'initial-color-mode';