import React, { useCallback } from 'react';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';

const isBrowser = typeof window !== "undefined"
/**
 * Generates the scroll-to-top floating action button.
 *
 * @return {JSX.Element} The JSX element representing the scroll-to-top button.
 */
const ScrollToTopFab: React.FC = () => {
    const trigger = useScrollTrigger({
        target: isBrowser ? window : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollToTop = useCallback(() => {
        isBrowser && window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={scrollToTop}
                color="primary"
                size="small"
                aria-label="Scroll back to top"
                sx={{
                    position: "fixed",
                    bottom: 32,
                    right: 32,
                    zIndex: 1,
                }}
            >
                <KeyboardArrowUp fontSize="medium" />
            </Fab>
        </Zoom>
    )
}

export default ScrollToTopFab