import React, { createContext } from 'react';
export const ContextIsHomeFirstMountDispatch = createContext();
export const ContextIsHomeFirstMountValue = createContext();


const initialValues = {
  isHomeFirstMount: true,
};


const reducer = (state, action) => {
  const { type, payload } = action;
  return { ...state, [ type ]: payload };
};


const ProviderIsHomeFirstMount = (props) => {
  const [ state, dispatch ] = React.useReducer(reducer, initialValues);

  return (
    <ContextIsHomeFirstMountDispatch.Provider value={dispatch}>
      <ContextIsHomeFirstMountValue.Provider value={state}>
        {props.children}
      </ContextIsHomeFirstMountValue.Provider>
    </ContextIsHomeFirstMountDispatch.Provider>
  );
};

export default ProviderIsHomeFirstMount;