import React, { useReducer, createContext, useLayoutEffect } from "react";
import siteConstants from "../../siteConstants.json"

// https://gist.github.com/daankauwenberg/bf0daf4d4a9a157a078ba4ec4559e3ab
const { nameCookieGdpr } = siteConstants

export const ContextGdprCookieConsentValue = createContext()
export const ContextGdprCookieConsentDispatch = createContext()


function getInitialCookieState() {
    const regex = new RegExp(`(?:(?:^|.*;\\s*)${nameCookieGdpr}\\s*\\=\\s*([^;]*).*$)|^.*$`)
    const cookie = document.cookie.replace(regex, "$1")
    return cookie.length ? {
        isCookieSet: 1,
        cookieConsent: JSON.parse(cookie),
        isCookieChecked: 1
    } : {
        isCookieSet: 0,
        cookieConsent: 0,
        isCookieChecked: 1
    }
}

// Initial value is cookie value OR prefered value but not yet set

const initialCookieValue = {
    isCookieSet: 0,
    cookieConsent: 0,
    isCookieChecked: 0
}

const ACCEPT_COOKIE = "ACCEPT_COOKIE"
const DECLINE_COOKIE = "DECLINE_COOKIE"
const SET_FUL_STATE = "SET_FUL_STATE"

const consentReducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'acceptCurrent':
            return {
                ...state,
                isCookieSet: 1,
            }
        case 'DECLINE_COOKIE':
            return {
                ...state,
                isCookieSet: 1,
                cookieConsent: 0,
            }
        case 'ACCEPT_COOKIE':
            return {
                ...state,
                isCookieSet: 1,
                cookieConsent: 1,
            }
        case 'SET_FUL_STATE':
            return {
                ...payload
            }
        default:
            throw new Error()
    }
}

/** ProviderGdprCookieConsent
 * providing context about current GDPR cookie in browser
 * and functions to accept or deny
 * @param  {} {children}
 */
const ProviderGdprCookieConsent = ({ children }) => {
    const [ state, dispatch ] = useReducer(consentReducer, initialCookieValue)

    const acceptCookie = () => {
        dispatch({
            type: ACCEPT_COOKIE,
            payload: 1
        })
    }


    const declineCookie = () => {
        dispatch({
            type: DECLINE_COOKIE,
            payload: 0
        })
    }

    useLayoutEffect(() => {
        const initialStateFromBrowser = getInitialCookieState()
        // console.log("cookie initialStateFromBrowser", initialStateFromBrowser)
        dispatch({
            type: SET_FUL_STATE,
            payload: initialStateFromBrowser
        })
    }, [ dispatch ])

    return <ContextGdprCookieConsentDispatch.Provider value={{ acceptCookie, declineCookie }}>
        <ContextGdprCookieConsentValue.Provider value={state}>
            {children}
        </ContextGdprCookieConsentValue.Provider>
    </ContextGdprCookieConsentDispatch.Provider>
}
export default ProviderGdprCookieConsent