import React, { createContext, useState } from "react";

export const contextCursorType = createContext({
    cursorType: "",
    cursorChangeHandler: () => { },
});

const ProviderCursorType = (props) => {
    const [ cursorType, setCursorType ] = useState("");

    const cursorChangeHandler = (cursorType) => {
        setCursorType(cursorType);
    };

    return (
        <contextCursorType.Provider
            value={{
                cursorType: cursorType,
                cursorChangeHandler: cursorChangeHandler,
            }}
        >
            {props.children}
        </contextCursorType.Provider>
    );
};

export default ProviderCursorType;