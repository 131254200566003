import React, { lazy, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import ScrollToTopFab from "./ScrollToTopFab.tsx";
import Header from "./_navigation/Header";
import DarkModeToggleButton from "./DarkModeToggleButton";
import LazyDynamicComponent from "./LazyDynamicComponent";
import { COMPONENTS_FILENAMES_IN_TEMPLATE as comps } from "./mapComponents"
import { useBreakpoint } from "./contextBreakpointMediaQ";

const LazySvgInParentPaddings = lazy(() => import("./svgInParentPaddings/SvgInParentPaddings.js"));
const LazyFooter = lazy(() => import('./_footer'));

const heightHeader = 80;

const Layout = (props) => {
  const {
    data,
    path,
    pageContext,
    children,
    containerStyles = {
      position: "relative",
      bgcolor: "primary2.main",
      color: "primary2.contrastText",
      px: [ "1px" ]
    }
  } = props;

  const { contentDir = "notFound" } = pageContext;
  const pageContentQueried = data?.pageContentQueried;
  const frontmatter = pageContentQueried?.frontmatter;
  const sections = frontmatter?.sections;
  const title = frontmatter?.title;
  const refLayout = useRef(null);
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const isFullUp = sections?.includes("FullUp");
  const optionsSvgInParentPaddingsAnimated = { strokeColor: theme.palette.primary2.light, fillColor: theme.palette.primary2.dark };

  return (
    <>
      <DarkModeToggleButton />
      <Header heightHeader={heightHeader} />
      <Container
        id="layout"
        maxWidth={false}
        ref={refLayout}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden",
          overflowY: 'hidden',
          position: "relative",
          px: [ 0.5, 1, 2, 3 ],
        }}
      >
        <Suspense>
          <LazySvgInParentPaddings refParent={refLayout} options={optionsSvgInParentPaddingsAnimated} />
        </Suspense>
        {isFullUp && (
          <section
            className="templFullUp"
            style={{
              top: `${heightHeader}px`,
              position: "relative",
            }}
            id="fullUp"
            key="fullUp"
          >
            <LazyDynamicComponent compProps={{ title, breakpoint }} chunkName={`${contentDir}-FullUp`} relPath={`${contentDir}/${comps.FullUp}`} isQuick={true} />
          </section>
        )}
        {children}
      </Container>
      <Suspense>
        <Container maxWidth="lg" className="templContFoot">
          <LazyFooter />
        </Container>
      </Suspense>
      <ScrollToTopFab />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;