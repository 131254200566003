import * as React from "react";
import { motion } from "framer-motion";
import { SideMenuItem } from "./SideMenuItem";
import { navLinks } from "../navLinks";

const variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, when: "beforeChildren" }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1, when: "beforeChildren" }
    }
};

/**
 * SideMenuList
 * called when display < md
 * animated when motion parent animates open or closed
 */
export const SideMenuList = () => (
    <motion.ul
        variants={variants}
        className="menuList"
        style={{
            fontSize: 'medium'
        }}
        key="SideMenuList"
    >
        {navLinks.map(({ title, path, dIconPath }, i) => (
            <SideMenuItem
                title={title}
                path={path}
                dIconPath={dIconPath}
                i={i}
                key={title}
            />
        ))}
    </motion.ul>
);


