import * as React from "react";
import { motion } from "framer-motion";
import { IconButton } from "@mui/material";
import * as stylesClay from "../../../styles/clay.module.css"

const Path = ({ color, ...rest }) => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke={color}
        strokeLinecap="round"
        {...rest}
    />
);

/**
 * HamburgerButton
 * shown when display < "md"
 * animated lines into x when isOpen === true
 * @param  {} {toggle
 * @param  {} isOpen
 * @param  {} color
 * @param  {} bgColor}
 */
export const HamburgerButton = ({ toggle, isOpen, color, bgColor }) => {
    return (
        <IconButton
            component={motion.button}
            className={`burger ${stylesClay.clay}`}
            edge="start"
            aria-label="menu"
            onTap={toggle}
            size="large"
            style={{
                cursor: "pointer",
                backgroundColor: bgColor
            }}
            key="ham"
        >

            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    variants={{
                        closed: { d: "M 2 2.5 L 20 2.5" },
                        open: { d: "M 3 16.5 L 17 2.5" }
                    }}
                    animate={isOpen ? "open" : "closed"}
                    color={color}
                    key="line-1"
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 }
                    }}
                    animate={isOpen ? "open" : "closed"}
                    transition={{ duration: 0.1 }}
                    color={color}
                    key="line-2"
                />
                <Path
                    variants={{
                        closed: { d: "M 2 16.346 L 20 16.346" },
                        open: { d: "M 3 2.5 L 17 16.346" }
                    }}
                    animate={isOpen ? "open" : "closed"}
                    color={color}
                    key="line-3"
                />
            </svg>
        </IconButton>
    )
};
