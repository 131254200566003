import React from 'react';
import Box from '@mui/material/Box';
// Adapted : https://codepen.io/aaroniker/pen/KGpXZo and https://github.com/narative/gatsby-theme-novela/blob/714b6209c5bd61b220370e8a7ad84c0b1407946a/%40narative/gatsby-theme-novela/src/components/Navigation/Navigation.Header.tsx
import { useDarkMode } from '../../theme/ContextDarkMode';

const styleWrapButton = {
  position: "fixed",
  zIndex: 1202,
  padding: '4px',
  display: "inline",
  borderStyle: "inset",
  borderWidth: "thin",
  "&.MuiPaper-rounded": {
    display: "flex",
    justifyContent: "center",
  },
}

const styleButton = {
  fontFamily: "inherit",
  height: "inherit",
  width: "inherit",
  zIndex: "inherit",
  opacity: 0.65,
  position: `relative`,
  alignSelf: "center",
  borderRadius: `5px`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  border: `none`,
  outline: `none`,
  background: `none`,
  cursor: `pointer`,
  padding: 0,
  appearance: `none`,
  transition: `transform 0.45s ease-in-out`,
  "&:focus": { opacity: 1 },
  "&:hover": { opacity: 1, transform: "rotateZ(360deg) scale(2)" },
}

const styleMoonOrSun = ({ darkMode }) => {
  return ({
    position: `relative`,
    width: `24px`,
    height: `24px`,
    borderRadius: `50%`,
    border: darkMode ? `4px solid yellow` : `none`,
    backgroundColor: darkMode ? `yellow` : `transparent`,
    transform: darkMode ? `scale(0.55)` : `scale(1)`,
    overflow: darkMode ? `visible` : `hidden`,
    boxShadow: darkMode ? `none` : `inset 8px -8px 0px 0px black`,

    "&:after": darkMode ? {
      content: `""`,
      width: `8px`,
      height: `8px`,
      borderRadius: `50%`,
      margin: `-4px 0 0 -4px`,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      boxShadow: `0 -23px 0 yellow, 0 23px 0 yellow, 23px 0 0 yellow, -23px 0 0 yellow, 15px 15px 0 yellow, -15px 15px 0 yellow, 15px -15px 0 yellow, -15px -15px 0 yellow`,
    } : {},
  })
}


/** DarkModeToggleButton
 * toggling colors used - "dark mode theme" with "light mode theme" 
 * consuming info from ContextDarkMode
 */
const DarkModeToggleButton = ({ top = 37, left = 20 }) => {
  const { state, toggleDarkMode } = useDarkMode()
  const { darkMode } = state
  return (
    <Box component="div"
      className='clay'
      sx={{ ...styleWrapButton, top: `${top}px`, left: `${left}px` }}
    >
      <Box component="button"
        onClick={toggleDarkMode}
        type="button"
        aria-label={darkMode ? `Svetlé farby` : `Tmavé farby`}
        title={darkMode ? `Svetlé farby` : `Tmavé farby`}
        sx={styleButton}
      >
        <Box component="div"
          sx={styleMoonOrSun({ darkMode })}
        >
          &nbsp;
        </Box>
      </Box>
    </Box>
  )
}

export default DarkModeToggleButton
